import React from "react"
import { GlobalStyle } from "../components/styles/GlobalStyles" 
import Seo from "../components/Seo"
import BlogHeader from "../components/BlogHeader"
import BlogList from "../components/BlogList"
import Footer from "../components/Footer"

const Blog = () => (
  <>
    <GlobalStyle />
    <Seo 
      title="NEWS" />
    <BlogHeader />
    <BlogList />
    <Footer />
  </>
)

export default Blog