import React, { useEffect } from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "../assets/images/logo.png"
import { FaBars, FaTimes } from "react-icons/fa"
import { IconContext } from "react-icons/lib"

const Header = () => {
  const [click, setClick] = useStateIfMounted(false)
  const [scroll, setScroll] = useStateIfMounted(false)

  const handleClick = () => setClick(!click)

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useEffect(() => {
    changeNav()
    window.addEventListener("scroll", changeNav)
  })

  return (
    <IconContext.Provider value={{ color: "#141414" }}>
      <NavWrapper active={scroll} click={click}>
        <Nav>
          <LogoLink to="/"><Img src={ Logo } alt="logo" /></LogoLink>

          <MobileIcon onClick={handleClick}>
            { click ? <FaTimes /> : <FaBars /> }
          </MobileIcon>

          <NavMenu onClick={handleClick} click={click}>
            <NavLink to="/#about">About</NavLink>
            <NavLink to="/#service">Service</NavLink>
            <NavLink to="/#project">Project</NavLink>
            <NavLink to="/#member">Member</NavLink>
            <NavLink to="/#news">News</NavLink>
            <NavLink to="/#contact">Contact</NavLink>
          </NavMenu>

        </Nav>
      </NavWrapper>
    </IconContext.Provider>
  )
}

export default Header

const NavWrapper = styled.div`
  background: ${({active}) => active ? "#F9F9F9" : "transparent"};
  box-shadow: ${({active}) => active ? "0 2px 7px 0 rgba(0,0,0,0.1)" : "none"};
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 999;
  transition: all 0.3s;
`
const Nav = styled.nav`
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
`
const LogoLink = styled(Link)`
  width: 200px;
  height: 80px;
  padding: 0 16px;
` 
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
const NavLink = styled(Link)`
  margin: 0 16px;
  text-decoration: none;
  color: #191919;
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  &::after {
    position: absolute;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: #241A19;
    bottom: -8px;
    transform: scale(0, 1);
    transform-origin: center top; 
    transition: transform 0.3s; 
  }
  &:hover::after {
    transform: scale(1, 1);
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    text-align: center;
    height: 70px;
    line-height: 70px;
    margin: 0;
    border-top: 1px solid #E5E5E5;
    &:last-child {
      border-bottom: 1px solid #E5E5E5;
    }
    &:after {
      display: none;
    }
    &:hover {
      background: #E5E5E5;
    }
  }
`
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: ${({ click }) => (click ? "0%" : "-1000px")};
    opacity: 1;
    transition: all 0.3s;
    background: #F9F9F9;
  }
`
const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 992px) {
    display: block;
    position: absolute;
    top: 25px;
    right: 16px;
    font-size: 27px;
    cursor: pointer;
    z-index: 999;
  }
`
