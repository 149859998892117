import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Blog from "./Blog"

const query = graphql`
  {
    allContentfulBlog(sort: {fields: createdAt, order: DESC}, limit: 15) {
      nodes {
        id
        title
        mainTitle
        createdAt(formatString: "YYYY年MM月DD日")
      }
    }
  }
`

const BlogList = () => {
  const data = useStaticQuery(query)
  const blogs = data.allContentfulBlog.nodes
  return (
    <Blog blogs={blogs} />
  )
}

export default BlogList

